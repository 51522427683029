import { Storage } from "aws-amplify";
import moment from "moment";

import { renderSpreadsheet } from "./render/xlsx";
import type { GroupReportType } from "./types";
import { makeData } from "./data";

import { downloadFile } from "utils/files";
import { GroupReport, Project } from "API";
import { replaceFileName } from "utils/report";

export * from "./types";

export async function download(
  report: GroupReport,
  groupId: string,
  projects: Project[],
  dates: string[]
) {
  try {
    const type = report.type;
    const dataType = report.data;
    const reportScope = JSON.parse(report.scope || "{}");

    const [data, file] = await Promise.all([
      makeData(
        type,
        dataType as GroupReportType,
        groupId,
        projects,
        dates,
        reportScope
      ),
      Storage.get(report.template.key, {
        download: true,
        cacheControl: "no-cache, no-store, must-revalidate",
      }),
    ]);

    // dataがvoidだったらここで終わり
    if (!data) return;

    const fileName = `${report.name}_${moment().format("YYYYMMDDHHmmss")}`;

    // phase2では使わない
    // if (type === "DOCUMENT") {
    //   for (const d in data) {
    //     const blob = await renderDocument(file.Body as Blob, d);
    //     await downloadFile(fileName, blob);
    //   }
    // }
    if (type === "SPREADSHEET") {
      const renderData = await renderSpreadsheet(
        file.Body as Blob,
        data,
        report
      );
      await downloadFile(
        replaceFileName(fileName),
        renderData.base64,
        "base64"
      );
    }
  } catch (e: any) {
    throw Error(e);
  }
}
