import { Schedule, Project } from "API";

type StockData = {
  stockingDate: string;
  project: ProjectStockData[];
};
type ProjectStockData = {
  id: string;
  reference: string;
  name: string;
  accountName: string;
  scheduleStockingDate: string;
  scheduleId: string;
  scheduleName: string;
  scheduleCase: string;
};

type ShipData = {
  shippingDate: string;
  project: ProjectShipCutData[];
};

type CutData = {
  cutDate: string;
  project: ProjectShipCutData[];
};

type ProjectShipCutData = {
  id: string;
  reference: string;
  name: string;
  accountName: string;
  scheduleCutDate: string;
  scheduleShippingDate: string;
  scheduleId: string;
  scheduleName: string;
  scheduleShipType: string;
  scheduleCase: string;
};

type PackData = {
  packagingDate: string;
  project: ProjectPackData[];
};

type ProjectPackData = {
  id: string;
  reference: string;
  name: string;
  accountName: string;
  scheduleId: string;
  scheduleName: string;
  schedulePackagingDate: string;
  scheduleProductName: string;
  scheduleProductPackageTypeName: string;
  scheduleProductQuantity: string;
};

// 予定の出力のためにprojectデータを集計します
export function makeProjectsToPlanData(
  projects: Project[],
  dates: string[]
): {
  stockData: StockData;
  shipData: ShipData;
  cutData: CutData;
  packData: PackData;
} {
  // 集計時に結果を入れる変数
  const pickedStockProjects: ProjectStockData[] = [];
  const pickedShipProjects: ProjectShipCutData[] = [];
  const pickedCutProjects: ProjectShipCutData[] = [];
  const pickedPackProjects: ProjectPackData[] = [];

  // Date中心に集計する
  // dates(= 今回対象としている日付の範囲)でloopを回し
  // scheduleのstockingDate/shippingDate/cutDateがdatesの中にあったら結果に含める
  for (const d of dates) {
    for (const p of projects) {
      // projectのscheduleの中から、stockingDateが今回対象としている日付の範囲にあるものを抽出する
      const stockSchedules = p.schedules?.items.filter(
        (s) => s?.stockingDate === d
      ) as Schedule[];

      // stockingDateが今回対象としている日付の範囲にあるものがなかったら終了
      if (stockSchedules.length !== 0) {
        // 今回対象としている日付の範囲にあるものがあったら、結果に含める
        for (const s of stockSchedules) {
          const project = {
            id: p.id,
            reference: p.reference || "-",
            name: p.name || "-",
            accountName: p.accountName || "-",
            scheduleStockingDate: s.stockingDate || "-",
            scheduleId: s.id || "-",
            scheduleName: s.name || "-",
            scheduleCase: getCaseCountInSchedule(s).toString(),
          };

          pickedStockProjects.push(project);
        }
      }

      // 同上の方法で、shippingDateが今回対象としている日付の範囲にあるものを抽出する
      const shipSchedules = p.schedules?.items.filter(
        (s) => s?.shippingDate === d
      ) as Schedule[];

      // shippingDateが今回対象としている日付の範囲にあるものがなかったら終了
      if (shipSchedules.length !== 0) {
        // 今回対象としている日付の範囲にあるものがあったら、結果に含める
        for (const s of shipSchedules) {
          const project = {
            id: p.id,
            reference: p.reference || "-",
            name: p.name || "-",
            accountName: p.accountName || "-",
            scheduleShippingDate: s.shippingDate || "-",
            scheduleId: s.id || "-",
            scheduleName: s.name || "-",
            scheduleShipType: s.shipType || "-",
            scheduleCase: getCaseCountInSchedule(s).toString(),
            scheduleCutDate: s.cutDate || "-",
          };

          pickedShipProjects.push(project);
        }
      }

      // cutDateが今回対象としている日付の範囲にあるものを抽出する
      const cutSchedules = p.schedules?.items.filter(
        (s) => s?.cutDate === d
      ) as Schedule[];

      // cutDateが今回対象としている日付の範囲にあるものがなかったら終了
      if (cutSchedules.length !== 0) {
        // 今回対象としている日付の範囲にあるものがあったら、結果に含める
        for (const s of cutSchedules) {
          const project = {
            id: p.id,
            reference: p.reference || "-",
            name: p.name || "-",
            accountName: p.accountName || "-",
            scheduleShippingDate: s.shippingDate || "-",
            scheduleId: s.id || "-",
            scheduleName: s.name || "-",
            scheduleShipType: s.shipType || "-",
            scheduleCase: getCaseCountInSchedule(s).toString(),
            scheduleCutDate: s.cutDate || "-",
          };

          pickedCutProjects.push(project);
        }
      }

      // packDateが今回対象としている日付と同じものを抽出
      const packSchedules = p.schedules?.items.filter(
        (s) => s?.packagingDate === d
      ) as Schedule[];

      // packDateが今回対象としている日付の範囲にあるものがなかったら終了
      if (packSchedules.length !== 0) {
        // 今回対象としている日付の範囲にあるものがあったら、結果に含める
        for (const s of packSchedules) {
          // productごとに作成する
          for (const product of s.products?.items || []) {
            if (product === null) continue;
            const project = {
              id: p.id,
              reference: p.reference || "-",
              name: p.name || "-",
              accountName: p.accountName || "-",
              scheduleId: s.id || "-",
              scheduleName: s.name || "-",
              schedulePackagingDate: s.packagingDate || "-",
              scheduleProductName: product.name || "-",
              scheduleProductPackageTypeName: product.packageTypeName || "-",
              scheduleProductQuantity: product.quantity?.toString() || "-",
            };

            pickedPackProjects.push(project);
          }
        }
      }
    }
  }

  return {
    stockData: {
      stockingDate: `${dates[0]} ~ ${dates[dates.length - 1]}`,
      project: pickedStockProjects,
    },
    shipData: {
      shippingDate: `${dates[0]} ~ ${dates[dates.length - 1]}`,
      project: pickedShipProjects,
    },
    cutData: {
      cutDate: `${dates[0]} ~ ${dates[dates.length - 1]}`,
      project: pickedCutProjects,
    },
    packData: {
      packagingDate: `${dates[0]} ~ ${dates[dates.length - 1]}`,
      project: pickedPackProjects,
    },
  };
}

// scheduleのcase数を計算する(scheduleの下のproductsのquantityの合算)
const getCaseCountInSchedule = (s: Schedule): number => {
  if (s.products === undefined || s.products === null) return 0;
  return s.products?.items.reduce((acc, cur) => {
    return acc + (cur?.quantity || 0);
  }, 0);
};
