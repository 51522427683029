/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, createContext, useContext, useState } from "react";
import { useEffect } from "react";
import { Storage } from "aws-amplify";
import { uuid } from "short-uuid";

import { useAlerts } from "./alerts";
import { useAuth } from "./auth";
import { useGantt } from "./gantt";

import type { GroupReport, Report } from "API";
import useDatalist from "hooks/datalist";
import { GraphQLInput } from "hooks/datalist";
import { useDialog } from "contexts/dialog";
import { download } from "utils/groupReport";
import { downloadTemplate } from "utils/report";

interface GroupReportsContextValue {
  loading: boolean;
  exporting: boolean;
  reports: GroupReport[];
  planReports: GroupReport[];
  woodReports: GroupReport[];
  invoiceReports: GroupReport[];
  create: (input: GraphQLInput) => Promise<void>;
  remove: (id: string) => Promise<void>;
  download: (report: GroupReport) => Promise<void>;
  downloadTemplate: (report: GroupReport | Report) => Promise<void>;
}

interface GroupReportsContextProps {
  children: ReactNode;
}

const GroupReportsContext = createContext<GroupReportsContextValue>({
  reports: [],
  planReports: [],
  woodReports: [],
  invoiceReports: [],
  loading: false,
  exporting: false,
  create: () => Promise.resolve(),
  remove: () => Promise.resolve(),
  download: () => Promise.resolve(),
  downloadTemplate: () => Promise.resolve(),
});

export const GroupReportsProvider = ({
  children,
}: GroupReportsContextProps) => {
  const { currentGroup } = useAuth();
  const { data, loading, refetch, create, remove } = useDatalist({
    query: "groupReportsByGroupId",
    variables: {
      groupId: currentGroup?.id,
    },
  });
  const { projects, dates } = useGantt();
  const [exporting, setExporting] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [exportingReports, setExportingReports] = useState<string[]>([]);

  const { open } = useDialog();
  const { addAlert } = useAlerts();

  useEffect(() => {
    refetch({
      groupId: currentGroup?.id,
    });
  }, [currentGroup?.id]);

  const createReport = async (input: GraphQLInput) => {
    // phase2ではDocument対応しないのでエラーを出す
    if (input.type === "DOCUMENT") {
      addAlert({
        message: "Phase2では案件横断帳票はDOCUMENT(WORD)に対応していません",
        severity: "error",
      });
      return;
    }
    await create("createGroupReport", {
      ...input,
      groupId: currentGroup?.id,
    });
  };

  const removeReport = async (id: string) => {
    open({
      title: "案件横断帳票を削除しますか？",
      content: "削除すると二度と戻せません",
      okText: "削除",
      onOk: async () => {
        const file = data.filter((d: GroupReport) => d.id === id)[0];
        await Storage.remove(file.template.key);
        await remove("deleteGroupReport", { id });
      },
    });
  };

  useEffect(() => {
    if (exporting && !error && exportingReports.length <= 0) {
      addAlert({
        message: `案件横断帳票が全て出力されました`,
        severity: "success",
      });
      setExporting(false);
      document.body.style.cursor = "";
      return setExportingReports([]);
    } else if (exporting && !error) {
      addAlert({
        message: `案件横断帳票を出力中です`,
        severity: "info",
      });
    } else if (error) {
      setError(false);
      setExporting(false);
      document.body.style.cursor = "";
      return setExportingReports([]);
    }
  }, [exportingReports]);

  const downloadGroupReport = async (report: GroupReport) => {
    setError(false);
    const exportId = uuid();
    setExporting(true);
    document.body.style.cursor = "progress";
    setExportingReports([...exportingReports, exportId]);
    try {
      if (currentGroup)
        await download(report, currentGroup?.id, projects, dates);
    } catch (err: any) {
      setError(true);
      if (typeof err === "string") {
        addAlert({
          message: `${report.name}が出力されませんでした: ${err}`,
          severity: "error",
        });
      } else if (err instanceof Error) {
        addAlert({
          message: `${report.name}が出力されませんでした: ${err.message}`,
          severity: "error",
        });
      } else {
        addAlert({
          message: `${report.name}が出力されませんでした`,
          severity: "error",
        });
      }
    } finally {
      setExportingReports(exportingReports.filter((e) => e !== exportId));
    }
  };

  return (
    <GroupReportsContext.Provider
      value={{
        reports: data,
        planReports: data
          ? data.filter((d: GroupReport) => d.data === "PLAN")
          : [],
        woodReports: data
          ? data.filter((d: GroupReport) => d.data === "WOOD")
          : [],
        invoiceReports: data
          ? data.filter((d: GroupReport) => d.data === "GROUPINVOICE")
          : [],
        loading,
        create: createReport,
        remove: removeReport,
        download: downloadGroupReport,
        downloadTemplate,
        exporting,
      }}
    >
      {children}
    </GroupReportsContext.Provider>
  );
};

export const useGroupReports = () => {
  const groupReportsContext = useContext(GroupReportsContext);

  if (groupReportsContext === undefined) {
    throw new Error("useGroupReports must be within GroupReportsProvider");
  }

  return groupReportsContext;
};
