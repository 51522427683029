import { GroupReportType } from "../types";

import { makeXlsxData } from "./xlsx";

import { Project } from "API";

export async function makeData(
  type: string,
  reportType: GroupReportType,
  groupId: string,
  projects: Project[],
  dates: string[],
  scope?: { [key: string]: string }
) {
  // phase2では使わない
  //if (type === "DOCUMENT") return getDocxData(reportType, groupId);
  if (type === "SPREADSHEET")
    return makeXlsxData(reportType, groupId, projects, dates, scope);
}
