import _ from "lodash";
import moment from "moment";

import type { SortedProjectDates, KeyDate, ProjectDateSortName } from "./types";

import { Project } from "API";
import { getStatusIndex } from "utils/status";

/**
 * 案件のスケジュールを指定された日付キーでソートします。
 *
 * @param {Project} project - ソート対象の案件
 * @param {KeyDate} dateName - ソートに使用する日付のキー。
 * @param {"asc" | "desc"} direction - ソート方向 (昇順,降順)
 * @returns {Project} ソートされたスケジュールを持つ案件。
 */
const sortSchedulesByDate = (
  project: Project,
  dateName: KeyDate,
  direction: "asc" | "desc"
) => {
  // 指定された方向にスケジュール個別のソートを行う
  project.schedules?.items.sort((a, b) => {
    if (direction === "asc") {
      return (
        moment(a && a[dateName]).valueOf() - moment(b && b[dateName]).valueOf()
      );
    } else if (direction === "desc") {
      return (
        moment(b && b[dateName]).valueOf() - moment(a && a[dateName]).valueOf()
      );
    }
    return 0;
  });

  return project;
};

/**
 * 案件を指定された日付フィールドとソート方向に基づいてソートします。
 *
 * @param {Project[]} projects - ソート対象の案件配列
 * @param {KeyDate} dateName - ソート基準の日付フィールド名
 * @param {"asc" | "desc"} direction - ソート方向 (昇順,降順)
 * @returns {Project[]} ソートされた案件配列
 */
const sortProjectsByDate = (
  projects: Project[],
  dateName: KeyDate,
  direction: "asc" | "desc"
) => {
  const defaultOrder: string = direction === "asc" ? "2100-1-1" : "2100-1-1";

  // 各プロジェクトごとに複数のスケジュールがあるため、最も早いスケジュールの日付を探す
  const earliestDatesByProject = projects.reduce(
    (result: SortedProjectDates[], project) => {
      if (!project.schedules || project.schedules.items.length === 0)
        return result.concat({
          id: project.id,
          earliestTargetDate: defaultOrder,
        });

      const earliestTargetDate = project.schedules?.items.map((item) =>
        item && item[dateName] ? item[dateName] : defaultOrder
      );

      // 指定された方向にスケジュール個別のソートを行う
      sortSchedulesByDate(project, dateName, direction);

      result.push({
        id: project.id,
        earliestTargetDate: _.min([
          ...earliestTargetDate,
          defaultOrder,
        ]) as string,
      });
      return result;
    },
    []
  );
  // 指定された方向に応じてプロジェクトをソートし、プロジェクトの配列を返す
  if (direction === "asc") {
    return earliestDatesByProject
      .sort(
        (a, b) =>
          moment(a.earliestTargetDate).valueOf() -
          moment(b.earliestTargetDate).valueOf()
      )
      .map((d) => projects.filter((p) => p.id === d.id)[0]);
  }
  if (direction === "desc") {
    return earliestDatesByProject
      .sort(
        (a, b) =>
          moment(b.earliestTargetDate).valueOf() -
          moment(a.earliestTargetDate).valueOf()
      )
      .map((d) => projects.filter((p) => p.id === d.id)[0]);
  }
  return projects;
};

export const sortProjects = (
  projects: Project[],
  sortName: ProjectDateSortName
) => {
  if (sortName === "sortByName") {
    return projects.sort((a, b) => a.name.localeCompare(b.name));
  }
  if (sortName === "sortByNameDesc") {
    return projects.sort((a, b) => b.name.localeCompare(a.name));
  }
  if (sortName === "sortByStatus") {
    return projects.sort(
      (a, b) => getStatusIndex(a.status) - getStatusIndex(b.status)
    );
  }
  if (sortName === "sortByStatusDesc") {
    return projects.sort(
      (a, b) => getStatusIndex(b.status) - getStatusIndex(a.status)
    );
  }
  if (sortName === "sortByStock") {
    return sortProjectsByDate(projects, "stockingDate", "asc");
  }
  if (sortName === "sortByPackage") {
    return sortProjectsByDate(projects, "packagingDate", "asc");
  }
  if (sortName === "sortByShip") {
    return sortProjectsByDate(projects, "shippingDate", "asc");
  }
  if (sortName === "sortByCut") {
    return sortProjectsByDate(projects, "cutDate", "asc");
  }
  if (sortName === "sortByCreate") {
    return projects.sort(
      (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
    );
  }
  if (sortName === "sortByUpdate") {
    return projects.sort(
      (a, b) => moment(a.updatedAt).valueOf() - moment(b.updatedAt).valueOf()
    );
  }
  if (sortName === "sortByStockDesc") {
    return sortProjectsByDate(projects, "stockingDate", "desc");
  }
  if (sortName === "sortByPackageDesc") {
    return sortProjectsByDate(projects, "packagingDate", "desc");
  }
  if (sortName === "sortByShipDesc") {
    return sortProjectsByDate(projects, "shippingDate", "desc");
  }
  if (sortName === "sortByCutDesc") {
    return sortProjectsByDate(projects, "cutDate", "desc");
  }
  if (sortName === "sortByCreateDesc") {
    return projects.sort(
      (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
    );
  }
  if (sortName === "sortByUpdateDesc") {
    return projects.sort(
      (a, b) => moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf()
    );
  }

  return projects;
};

export const removeBeforeIdx = (projects: any) => {
  projects
    .filter((p: any) => p.beforeIdx !== undefined)
    .forEach((p: any) => {
      delete p.beforeIdx;
    });
};
