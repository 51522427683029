import { API } from "aws-amplify";
import moment from "moment";

import { SummarizedInvoicesOutput, SummarizedInvoice } from "API";
import { listSummarizedInvoices } from "graphql/queries";
import { getStatusText } from "utils/status";

export async function listSummarizedInvoicesQuery(
  groupId: string,
  key: string,
  startDate: string,
  endDate: string
): Promise<SummarizedInvoicesOutput> {
  const result: SummarizedInvoicesOutput = {
    __typename: "SummarizedInvoicesOutput",
    key: key,
    items: [],
  };
  const limit = 100; //PAX-651 ページネーション毎回5個の請求を取得
  let nextToken = null;
  do {
    const response = (await API.graphql({
      query: listSummarizedInvoices,
      variables: {
        input: {
          key,
          groupId,
          startDate,
          endDate,
          limit,
          nextToken,
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })) as { data: { listSummarizedInvoices: SummarizedInvoicesOutput } };

    result.items = [
      ...(result.items || []),
      ...(response.data.listSummarizedInvoices.items?.filter(
        (item): item is SummarizedInvoice => item !== null
      ) || []),
    ];

    nextToken = response.data.listSummarizedInvoices.nextToken;
  } while (nextToken);

  return result;
}

// SummarizedInvoiceに対して以下を行う
// 1. createdAtとupdatedAtをJST(YYYY-MM-DD HH24:MI:SS)にする
// 2. recordingDateをJST(YYYY-MM-DD)にする
// 3. projectStatusを日本語にする
export const reformatSummarizedInvoices = (
  items: SummarizedInvoice[]
): SummarizedInvoice[] => {
  type DateTimeKeys = keyof Pick<
    SummarizedInvoice,
    "createdAt" | "updatedAt" | "lineCreatedAt" | "lineUpdatedAt"
  >;
  type DateKeys = keyof Pick<
    SummarizedInvoice,
    "recordingDate" | "issueDate" | "billingDate"
  >;

  const toDateTimeKeys: DateTimeKeys[] = [
    "createdAt",
    "updatedAt",
    "lineCreatedAt",
    "lineUpdatedAt",
  ];
  const toDateKeys: DateKeys[] = ["recordingDate", "issueDate", "billingDate"];

  return items
    .sort((a, b) => a.lineOrder - b.lineOrder)
    .map((item) => {
      toDateTimeKeys.forEach((key) => {
        // 無効な日付の場合は空文字にする
        if (!moment(item[key] as string).isValid()) {
          item[key] = "";
        }
        if (item[key]) {
          item[key] = moment(item[key]).format("YYYY-MM-DD HH:mm:ss");
        }
      });
      toDateKeys.forEach((key) => {
        // 無効な日付の場合は空文字にする
        if (!moment(item[key]).isValid()) {
          item[key] = "";
        }
        if (item[key]) {
          item[key] = moment(item[key]).format("YYYY-MM-DD");
        }
      });
      // 案件ステータス
      if (item["projectStatus"]) {
        item["projectStatus"] = getStatusText(item["projectStatus"]);
      }
      return item;
    });
};
