import { VisibleKeyDates, ProjectFilter } from "./types";

import { Project } from "API";

export function filterProjects(
  projects: Project[],
  filter: ProjectFilter
): Project[] {
  const { statusFilter, datesFilter, visibleKeyDates } = filter;
  let filteredProjects = [];

  filteredProjects = filterProjectsByStatus(projects, statusFilter);
  filteredProjects = filterProjectsByDates(
    filteredProjects,
    datesFilter,
    visibleKeyDates
  );

  return filteredProjects;
}

export function filterProjectsByStatus(
  projects: Project[],
  statusFilter: string[]
): Project[] {
  return projects.filter((p) => statusFilter.includes(p.status as string));
}

const keyDates: (keyof VisibleKeyDates)[] = [
  "stockingDate",
  "packagingDate",
  "shippingDate",
  "cutDate",
];

/**
 * スケジュールの日付範囲がフィルターの日付範囲に少しでも含まれているかどうかのチェック。
 *
 * @param {string[]} scheduleDate - スケジュールの日付範囲を表す配列。
 * @param {string[]} filterDate - フィルターの日付範囲を表す配列。
 * @returns {boolean} - スケジュールの日付範囲がフィルターの日付範囲に少しでも含まれている場合は true
 */
function isDateRangeContained(scheduleDates: string[], filterDates: string[]) {
  const startScheduleDate = new Date(scheduleDates[0]);
  const endScheduleDate = new Date(scheduleDates.at(-1)!);
  const startFilterDate = new Date(filterDates[0]);
  const endFilterDate = new Date(filterDates.at(-1)!);

  return (
    startScheduleDate <= endFilterDate && endScheduleDate >= startFilterDate
  );
}

/**
 * 案件をスケジュール上で指定された日付でフィルタリングします。
 * @param {Project[]} projects - フィルター対象の案件の配列
 * @param {string[] | null} datesFilter - フィルターする日付の配列。nullの場合はフィルターなし
 * @param {VisibleKeyDates} visibleKeyDates - 各工程の日付を取り出すためのキー（入荷、梱包、出荷、カット）
 * @returns {Project[]} - 日付でフィルタリングされた案件の配列
 */
export function filterProjectsByDates(
  projects: Project[],
  datesFilter: string[] | null,
  visibleKeyDates: VisibleKeyDates
): Project[] {
  const filteredProjects = projects.reduce<Project[]>((result, project) => {
    if (!project.schedules || !project.schedules.items) return result;

    const filteredKeyDates = project.schedules.items.filter((schedule) => {
      if (!schedule) return false;

      if (!datesFilter || datesFilter.length === 0) return true;

      const scheduleDates: string[] = [];

      keyDates.forEach((process) => {
        const processDate = schedule[process];
        if (
          visibleKeyDates[process] &&
          processDate &&
          !scheduleDates.includes(processDate)
        ) {
          scheduleDates.push(processDate);
        }
      });

      return isDateRangeContained(scheduleDates, datesFilter);
    });

    if (filteredKeyDates.length > 0) {
      project.schedules.items = filteredKeyDates;
      result.push(project);
    }

    return result;
  }, []);

  return filteredProjects;
}
