import moment, { Moment } from "moment";

/**
 * Formats the input date as a string using the specified format.
 * @param {string | null | undefined} date - The input date to be formatted.
 * @param {string} [format="MM/DD"] - The format to use when formatting the date (default is "MM/DD").
 * @returns {string} - The formatted date string or "-" if the input date is not valid.
 */
export function formatDate(
  date: string | null | undefined,
  format: string = "MM/DD"
): string {
  if (!moment(date).isValid()) return "-";
  return moment(date).format(format);
}

/**
 * Generates an array of date strings for each day between the specified start and end dates (inclusive).
 * @param {string | Moment} startDate - The start date in "YYYY-MM-DD" format.
 * @param {string | Moment} endDate - The end date in "YYYY-MM-DD" format.
 * @returns {string[]} - An array of date strings in "YYYY-MM-DD" format for each day between the start and end dates.
 */
export const enumerateDaysBetweenDates = (
  startDate: string | Moment,
  endDate: string | Moment
): string[] => {
  const now = moment(startDate).clone();
  const retDates = [];

  while (now.isSameOrBefore(moment(endDate))) {
    retDates.push(now.format("YYYY-MM-DD"));
    now.add(1, "d");
  }
  return retDates;
};

export const isSaturday = (day: string) => moment(day).day() === 6;
export const isSunday = (day: string) => moment(day).day() === 0;
export const isHoliday = (day: string, holidays: any) =>
  holidays?.find((holiday: any) => holiday?.date === day);

export const drawHolidayColor = (day: string, holidays: any) => {
  if (isHoliday(day, holidays) || isSunday(day)) {
    return "!bg-rose-100 !text-rose-800";
  } else if (isSaturday(day)) {
    return "!bg-sky-100 !text-indigo-800";
  }
  return "";
};

export const drawHolidayBorder = (day: string, holidays: any) => {
  let borderValue = "";
  // 当日
  const today = isSaturday(day) || isSunday(day) || isHoliday(day, holidays);
  // 前日
  let targetDate = moment(day).subtract(1, "d").format("YYYY-MM-DD");
  const yesterday =
    isSaturday(targetDate) ||
    isSunday(targetDate) ||
    isHoliday(targetDate, holidays) ||
    !today;
  // 翌日
  targetDate = moment(day).add(1, "d").format("YYYY-MM-DD");
  const tomorrow =
    isSaturday(targetDate) ||
    isSunday(targetDate) ||
    isHoliday(targetDate, holidays) ||
    !today;
  // 設定するCSS
  if (!yesterday || !tomorrow) borderValue += "border-solid ";
  if (!yesterday) borderValue += "border-s-2 border-s-white ";
  if (!tomorrow) borderValue += "border-e-2 border-e-white ";
  return borderValue.trim();
};
