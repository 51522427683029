import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import moment from "moment";

export default function useQuery<QueryType>(
  query: string,
  defaultValue: QueryType,
  toData?: (data: string) => QueryType,
  toQuery?: (data: QueryType) => string
) {
  const [data, setData] = useState<QueryType>(defaultValue);
  const router = useRouter();

  if (typeof defaultValue === "boolean") {
    toData = toData || (((data: string) => data === "true") as any);
    toQuery = toQuery || ((data) => (data as boolean).toString());
  }

  if (typeof defaultValue === "number") {
    toData = toData || (((data: string) => parseFloat(data)) as any);
    toQuery = toQuery || ((data) => (data as number).toString());
  }

  if (defaultValue instanceof Date) {
    toData = toData || (((data: string) => moment(data).toDate()) as any);
    toQuery = toQuery || ((data) => moment(data as Date).format("YYYY-MM-DD"));
  }

  if (
    typeof defaultValue !== "string" &&
    typeof defaultValue !== "boolean" &&
    typeof defaultValue !== "boolean" &&
    defaultValue instanceof Date
  ) {
    if (!toData)
      throw new Error("toData is required to convert query string to data");
    if (!toQuery)
      throw new Error("toQuery is required to convert data to string");
  }

  useEffect(() => {
    if (!router.query[query]) return update(defaultValue);

    const newValue = toData
      ? toData(router.query[query] as string)
      : (router.query[query] as QueryType);
    setData(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query[query]]);

  const update = (newData: any) => {
    router.query[query] = toQuery ? toQuery(newData) : newData;
    router.push(router);
  };

  return [data, update] as const;
}
