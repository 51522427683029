
import moment from "moment";

import { formatDate, enumerateDaysBetweenDates } from "./dates";
import { M3Schedule } from "./types";

import { Project, Schedule } from "API";

const updateM3 = (
  date: string | null | undefined,
  value: number,
  storage: Record<string, number>
) => {
  const formattedDate = formatDate(date);
  if (!storage[formattedDate]) {
    storage[formattedDate] = 0;
  }
  storage[formattedDate] += Math.ceil(value);
};

const updateAccumulation = (
  date: string | null | undefined,
  value: number,
  accumulation: Record<string, number>
) => {
  const formattedDate = formatDate(date);
  if (!accumulation[formattedDate]) {
    accumulation[formattedDate] = 0;
  }
  accumulation[formattedDate] += Math.ceil(value);
};

interface Result {
  startDate: string;
  endDate: string;
  accumulation: Record<string, number>;
  stockingM3: Record<string, number>;
  packagingM3: Record<string, number>;
  shippingM3: Record<string, number>;
  cutM3: Record<string, number>;
}

export function getM3Schedule(projects: Project[]): M3Schedule {
  if (projects.length === 0) return {};

  const initialResult: Result = {
    startDate: "2100-1-1",
    endDate: "1990-1-1",
    accumulation: {},
    stockingM3: {},
    packagingM3: {},
    shippingM3: {},
    cutM3: {},
  };

  const calculatedM3 = projects.reduce((result, project) => {
    const { schedules } = project;
    if (!schedules || !schedules.items || schedules?.items.length === 0)
      return result;

    (schedules.items as Schedule[]).forEach(
      ({
        m3,
        stockingDate,
        shippingDate,
        packagingDate,
        cutDate,
        shipType,
      }) => {
        if (moment(stockingDate).isBefore(moment(result.startDate)))
          result.startDate = stockingDate as string;
        if (moment(shippingDate).isAfter(moment(result.endDate)))
          result.endDate = shippingDate as string;

        if (!m3) return result;

        updateM3(stockingDate, m3, result.stockingM3);
        updateM3(packagingDate, m3, result.packagingM3);
        updateM3(shippingDate, m3, result.shippingM3);

        if (cutDate) updateM3(cutDate, m3, result.cutM3);

        updateAccumulation(stockingDate, m3, result.accumulation);

        // subtract from shipping date for normal ship type, cut date for van shipment
        if (shipType === "バン" && cutDate) {
          updateAccumulation(cutDate, -m3, result.accumulation);
        } else {
          updateAccumulation(shippingDate, -m3, result.accumulation);
        }
      }
    );
    return result;
  }, initialResult);

  // get a list of dates between earliest stocking date and latest shipping date
  const dates = enumerateDaysBetweenDates(
    calculatedM3.startDate,
    calculatedM3.endDate
  );

  // calculate total m3 per day
  const totalM3 = dates.reduce((result: any, date) => {
    const today = formatDate(date);
    const yesterday = formatDate(moment(date).subtract(1, "d").toString());
    // copy from previous day first, and then apply accumulation
    result[today] = result[yesterday] || 0;
    result[today] += calculatedM3.accumulation[today] || 0;
    return result;
  }, {});

  return {
    stockingM3: calculatedM3.stockingM3,
    packagingM3: calculatedM3.packagingM3,
    shippingM3: calculatedM3.shippingM3,
    cutM3: calculatedM3.cutM3,
    totalM3,
  };
}
