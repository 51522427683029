import moment, { Moment } from "moment";

import type { VisibleKeyDates } from "./types";

import { Schedule } from "API";

/**
 * Returns an object with the start and end dates for the focused schedule.
 * @param {Schedule} schedule - The schedule of interest.
 * @param {string[]} dates - The array of date strings.
 * @param {KeyDatesVisibility} visibleKeyDates - The visibility of the key dates.
 * @returns {{startDate: Date, endDate: Date}} - An object containing the start and end dates.
 */
export function getFocusedDateRange(
  schedule: Schedule,
  dates: string[],
  visibleKeyDates: VisibleKeyDates
): { startDate: Date; endDate: Date } {
  const keyDateMoments = getKeyDateMoments(schedule);
  const [initialStartDate, initialEndDate] = getInitialDateRange(dates);

  const startDate = getNewStartDate(
    initialStartDate,
    keyDateMoments,
    visibleKeyDates
  );
  const endDate = getNewEndDate(
    initialEndDate,
    keyDateMoments,
    visibleKeyDates
  );

  const adjustedDates = adjustDateRange(startDate, endDate);

  return {
    startDate: adjustedDates.startDate.toDate(),
    endDate: adjustedDates.endDate.toDate(),
  };
}

/**
 * Converts schedule key dates in a schedule to Moment.js objects.
 * @param {Schedule} keyDate - The key dates of the schedule.
 * @returns {Record<string, Moment>} - An object with key date names and their Moment.js representations.
 */
function getKeyDateMoments(keyDate: Schedule): Record<string, Moment> {
  return {
    stock: moment(keyDate.stockingDate),
    pkg: moment(keyDate.packagingDate),
    ship: moment(keyDate.shippingDate),
    cut: moment(keyDate.cutDate),
  };
}

/**
 * Extracts the initial start and end dates from the given date strings array.
 * @param {string[]} dates - The array of date strings.
 * @returns {[Moment, Moment]} - A tuple containing the initial start and end dates as Moment.js objects.
 */
function getInitialDateRange(dates: string[]): [Moment, Moment] {
  return [moment(dates[0]), moment(dates[dates.length - 1])];
}

/**
 * Determines the new start date based on key date visibility and validity.
 * @param {Moment} initialStartDate - The initial start date.
 * @param {Record<string, Moment>} keyDateMoments - The key date moments.
 * @param {KeyDatesVisibility} visibleKeyDates - The visibility of the key dates.
 * @returns {Moment} - The new start date.
 */
function getNewStartDate(
  initialStartDate: Moment,
  keyDateMoments: Record<string, Moment>,
  visibleKeyDates: VisibleKeyDates
): Moment {
  if (keyDateMoments.stock.isValid() && visibleKeyDates.stockingDate) {
    return keyDateMoments.stock;
  } else if (
    (!keyDateMoments.stock.isValid() || !visibleKeyDates.stockingDate) &&
    keyDateMoments.pkg.isValid() &&
    visibleKeyDates.packagingDate
  ) {
    return keyDateMoments.pkg;
  }
  return initialStartDate;
}

/**
 * Determines the new end date based on key date visibility and validity.
 * @param {Moment} initialEndDate - The initial end date.
 * @param {Record<string, Moment>} keyDateMoments - The key date moments.
 * @param {KeyDatesVisibility} visibleKeyDates - The visibility of the key dates.
 * @returns {Moment} - The new end date.
 */
function getNewEndDate(
  initialEndDate: Moment,
  keyDateMoments: Record<string, Moment>,
  visibleKeyDates: VisibleKeyDates
): Moment {
  if (keyDateMoments.cut.isValid() && visibleKeyDates.cutDate) {
    return keyDateMoments.cut;
  } else if (keyDateMoments.ship.isValid() && visibleKeyDates.shippingDate) {
    return keyDateMoments.ship;
  } else if (
    (!keyDateMoments.ship.isValid() || !visibleKeyDates.shippingDate) &&
    keyDateMoments.pkg.isValid() &&
    visibleKeyDates.packagingDate
  ) {
    return keyDateMoments.pkg;
  }
  return initialEndDate;
}

/**
 * Adjusts the start and end dates to ensure a minimum range of 7 days.
 * @param {Moment} startDate - The current start date.
 * @param {Moment} endDate - The current end date.
 * @returns {{startDate: Moment, endDate: Moment}} - An object containing the adjusted start and end dates.
 */
function adjustDateRange(
  startDate: Moment,
  endDate: Moment
): { startDate: Moment; endDate: Moment } {
  const diff = endDate.diff(startDate, "d");

  if (diff < 7) {
    const adjustment = (7 - diff) / 2;
    endDate = endDate.add(adjustment, "d");
    startDate = startDate.subtract(adjustment, "d");
  }

  return { startDate, endDate };
}
