
import moment from "moment";

import { formatDate, enumerateDaysBetweenDates } from "./dates";
import { CaseSchedule } from "./types";

import { Project, Schedule } from "API";

const updateCase = (
  date: string | null | undefined,
  value: number,
  storage: Record<string, number>
) => {
  const formattedDate = formatDate(date);
  if (!storage[formattedDate]) {
    storage[formattedDate] = 0;
  }
  storage[formattedDate] += Math.ceil(value);
};

const updateAccumulation = (
  date: string | null | undefined,
  value: number,
  accumulation: Record<string, number>
) => {
  const formattedDate = formatDate(date);
  if (!accumulation[formattedDate]) {
    accumulation[formattedDate] = 0;
  }
  accumulation[formattedDate] += Math.ceil(value);
};

interface Result {
  startDate: string;
  endDate: string;
  accumulation: Record<string, number>;
  stockingCase: Record<string, number>;
  packagingCase: Record<string, number>;
  shippingCase: Record<string, number>;
  cutCase: Record<string, number>;
}

export function getCaseSchedule(projects: Project[]): CaseSchedule {
  if (projects.length === 0) return {};

  const initialResult: Result = {
    startDate: "2100-1-1",
    endDate: "1990-1-1",
    accumulation: {},
    stockingCase: {},
    packagingCase: {},
    shippingCase: {},
    cutCase: {},
  };

  const calculatedCase = projects.reduce((result, project) => {
    const { schedules } = project;
    if (!schedules || !schedules.items || schedules?.items.length === 0)
      return result;

    (schedules.items as Schedule[]).forEach(
      ({
        case: cs,
        stockingDate,
        shippingDate,
        packagingDate,
        cutDate,
        shipType,
      }) => {
        if (moment(stockingDate).isBefore(moment(result.startDate)))
          result.startDate = stockingDate as string;
        if (moment(shippingDate).isAfter(moment(result.endDate)))
          result.endDate = shippingDate as string;

        if (!cs) return result;

        updateCase(stockingDate, cs, result.stockingCase);
        updateCase(packagingDate, cs, result.packagingCase);
        updateCase(shippingDate, cs, result.shippingCase);

        if (cutDate) updateCase(cutDate, cs, result.cutCase);

        updateAccumulation(stockingDate, cs, result.accumulation);

        // subtract from shipping date for normal ship type, cut date for van shipment
        if (shipType === "バン" && cutDate) {
          updateAccumulation(cutDate, -cs, result.accumulation);
        } else {
          updateAccumulation(shippingDate, -cs, result.accumulation);
        }
      }
    );
    return result;
  }, initialResult);

  const dates = enumerateDaysBetweenDates(
    calculatedCase.startDate,
    calculatedCase.endDate
  );

  // calculate total cases per day
  const totalCase = dates.reduce((result: any, date) => {
    const today = formatDate(date);
    const yesterday = formatDate(moment(date).subtract(1, "d").toString());
    // copy from previous day first, and then apply accumulation
    result[today] = result[yesterday] || 0;
    result[today] += calculatedCase.accumulation[today] || 0;
    return result;
  }, {});

  return {
    stockingCase: calculatedCase.stockingCase,
    packagingCase: calculatedCase.packagingCase,
    shippingCase: calculatedCase.shippingCase,
    cutCase: calculatedCase.cutCase,
    totalCase,
  };
}
