import axios from "axios";

import { GroupReport } from "API";

export async function renderSpreadsheet(
  blob: Blob,
  data: any,
  report: GroupReport
) {
  const body = new FormData();

  body.append("file", blob);
  body.append("data", JSON.stringify(data));
  body.append("report", JSON.stringify(report));

  const res = await axios.post("/api/xlsx", body);

  return res.data;
}
