import { makeProjectsToPlanData } from "./project";
import {
  getProductDataByScheduleId,
  listSummarizedMaterialsQuery,
} from "./product";
import {
  listSummarizedInvoicesQuery,
  reformatSummarizedInvoices,
} from "./invoice";

import { Project, ModelProductConnection, SummarizedInvoice } from "API";
import { GroupReportType } from "utils/groupReport/types";

export async function makeXlsxData(
  reportType: GroupReportType,
  groupId: string,
  projects: Project[],
  dates: string[],
  scope?: { [key: string]: string }
) {
  if (reportType === "PLAN") {
    // projectsのscheduleごとにproductを持ってきてセットする
    const newProjects = projects.map(async (p) => {
      const newSchedules = p.schedules?.items.map(async (s) => {
        if (s?.id) {
          // ここで取得
          const products = await getProductDataByScheduleId(s.id);
          const connection: ModelProductConnection = {
            __typename: "ModelProductConnection",
            items: products,
          };
          s.products = connection;
        }
        return s;
      });
      if (
        newSchedules !== undefined &&
        p.schedules !== null &&
        p.schedules !== undefined
      ) {
        p.schedules.items = await Promise.all(newSchedules);
      }
      return p;
    });
    return makeProjectsToPlanData(await Promise.all(newProjects), dates);
  } else if (reportType === "WOOD") {
    const { materialTypeId } = scope || {};

    if (!materialTypeId) throw Error("materialTypeId is not defined");

    const startDate = dates[0];
    const endDate = dates.at(-1) || dates[0];

    const summarizedMaterials = await listSummarizedMaterialsQuery(
      groupId,
      materialTypeId,
      startDate,
      endDate
    );

    return { materials: summarizedMaterials };
  } else if (reportType === "GROUPINVOICE") {
    const startDate = dates[0];
    const endDate = dates.at(-1) || dates[0];

    const keys = ["issueDate", "billingDate", "recordingDate"];
    const summarizedInvoicesPromises = keys.map((key) =>
      listSummarizedInvoicesQuery(groupId, key, startDate, endDate)
    );

    const summarizedInvoices = await Promise.all(summarizedInvoicesPromises);
    const data: any = {};
    const dateRange = `${startDate} ~ ${endDate}`;

    // レンダリング時に<sheet名 + Data>で参照する
    for (const invoice of summarizedInvoices) {
      const keyData = invoice.key.replace("Date", "Data");
      data[keyData] = {
        [invoice.key]: dateRange,
        invoices: reformatSummarizedInvoices(
          invoice.items?.filter(
            (item): item is SummarizedInvoice => item !== null
          ) || []
        ),
      };
    }

    return data;
  }
}
