
import moment from "moment";

// shift 1 day forward
export const shiftOneDay = (dateList: string[]) => {
  const startDate = moment(dateList[0]).add(1, "d").toDate();

  const endDate = moment(dateList[dateList.length - 1])
    .add(1, "d")
    .toDate();

  return {
    startDate,
    endDate,
  };
};

// shift 1 day behind
export const unshiftOneDay = (dateList: string[]) => {
  const startDate = moment(dateList[0]).subtract(1, "d").toDate();

  const endDate = moment(dateList[dateList.length - 1])
    .subtract(1, "d")
    .toDate();

  return {
    startDate,
    endDate,
  };
};
