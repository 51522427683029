
import moment, { Moment } from "moment";

import { formatDate } from "./dates";
import type { ScheduleUpdateParams, KeyDate } from "./types";

import { Schedule } from "API";

export function updateSchedule({
  schedule,
  field,
  date,
}: ScheduleUpdateParams): Schedule {
  let stock = moment(schedule.stockingDate);
  let pkg = moment(schedule.packagingDate);
  let ship = moment(schedule.shippingDate);
  let cut = moment(schedule.cutDate);

  const updateDate = (field: KeyDate, date: Moment): void => {
    schedule[field] = formatDate(date.toString(), "YYYY-MM-DD");
  };

  const newDate = moment(date);

  // never goes pass cut date
  if (newDate.isAfter(cut, "d")) {
    updateDate(field, cut);
  } else {
    updateDate(field, newDate);
  }

  switch (field) {
    case "stockingDate":
      if (stock.isAfter(pkg, "d")) updateDate("packagingDate", stock);
      if (stock.isAfter(ship, "d")) updateDate("shippingDate", stock);
      break;
    case "packagingDate":
      if (pkg.isAfter(ship, "d")) updateDate("shippingDate", pkg);
      if (pkg.isBefore(stock, "d")) updateDate("stockingDate", pkg);
      break;
    case "shippingDate":
      if (ship.isBefore(pkg, "d")) updateDate("packagingDate", ship);
      if (ship.isBefore(stock, "d")) updateDate("stockingDate", ship);
      break;
    default:
      break;
  }

  return schedule;
}
